/**
 * @generated SignedSource<<0abe610b9c0963c570319e60350ee792>>
 * @relayHash 6b6ce63667adc3e6725d3be457b5bc69
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/480.0.0-2024-09-16T15:03:32.028Z/TrackErrorPageViewMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TrackErrorPageInput = {
  clientMutationId?: string | null;
  pageType: string;
};
export type TrackErrorPageViewMutation$variables = {
  input: TrackErrorPageInput;
};
export type TrackErrorPageViewMutation$data = {
  readonly trackErrorPage: {
    readonly success: boolean | null;
  } | null;
};
export type TrackErrorPageViewMutation = {
  response: TrackErrorPageViewMutation$data;
  variables: TrackErrorPageViewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrackErrorPagePayload",
    "kind": "LinkedField",
    "name": "trackErrorPage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrackErrorPageViewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrackErrorPageViewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-buyer-finding/480.0.0-2024-09-16T15:03:32.028Z/TrackErrorPageViewMutation",
    "metadata": {},
    "name": "TrackErrorPageViewMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "33d7b46911981b9708128dd0bb950a18";

export default node;
